import scrollToElement from "scroll-to-element";

export function abortDrag(e) {
  e.preventDefault();
  return false;
}

export function mapColor(input, saturation) {
  const r = 255 - Math.abs(Math.sin(input) * saturation);
  const g = 255 - Math.abs(Math.sin(input + 45) * saturation);
  const b = 255 - Math.abs(Math.sin(input + 90) * saturation);

  return `${ r }, ${ g }, ${ b }`;
}

export function scrollListen(func) {
  document.addEventListener("scroll", () => {
    const scrollPx = document.scrollingElement.scrollTop;
    const scrollPercent = Math.round(
      document.scrollingElement.scrollTop / (document.scrollingElement.scrollHeight - document.scrollingElement.clientHeight + .1) * 100
    );

    window.requestAnimationFrame(() => {
      func(scrollPx, scrollPercent)
    });
  });
}

export function scrollTop() {
  scrollToElement("#main-title", {
    offset: -300,
    duration: 500
  });
}

export function scrollProjects() {
  scrollToElement("#projects", {
    offset: -100,
    duration: 500
  });
}

export function scrollContact() {
  scrollToElement("#footer", {
    offset: 100,
    duration: 500
  });
}