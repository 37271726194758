export default function sketch( ctx, cnv ) {
  function random( min, max ) {
    return Math.random() * ( max - min ) + min;
  }

  class Vector {
    constructor( x, y ) {
      this.x = x;
      this.y = y;
    }

    add( vec ) {
      this.x += vec.x;
      this.y += vec.y;
    }

    mul( vec ) {
      this.x *= vec.x;
      this.y *= vec.y;
    }
  }

  class Particle {
    constructor() {
      this.init();
    }

    init() {
      this.size = random( 2, 4.5 );
      this.pos = new Vector( random( 0, window.innerWidth ), random( 0, window.innerHeight ) );
      this.spe = new Vector( random( -1.5, 1.5 ), random( -1.5, 1.5 ) );
    }

    update() {
      this.pos.add( this.spe );

      if ( this.pos.x < 0 ||
        this.pos.x > window.innerWidth ||
        this.pos.y > window.innerHeight ||
        this.pos.y < 0 ) {

        this.init();
      }
    }

    draw() {
      ctx.moveTo(this.pos.x, this.pos.y);
      ctx.ellipse( this.pos.x, this.pos.y, this.size, this.size, 0, 0, Math.PI * 2 );
    }
  }

  // ***** setup *****
  window.addEventListener( "resize", () => {
    cnv.setAttribute( "width", window.innerWidth );
    cnv.setAttribute( "height", window.innerHeight );
    ctx.fillStyle = "rgba(255, 255, 255, .2)";
  } );

  const particles = [];
  for ( let i = 0; i < 20; i++ ) {
    particles.push( new Particle() );
  }

  // ***** update *****
  function update() {
    ctx.fillStyle = "rgba(255, 255, 255, .2)";
    ctx.clearRect(0, 0, cnv.width, cnv.height);
    ctx.beginPath();

    particles.map( particle => {
      particle.update();
      particle.draw();
      return null;
    } );

    ctx.fill();
    window.requestAnimationFrame( update );
  }

  update();
}