import React from "react";
import style from "../style/grid.scss";

function Items(images) {
  return images.map((item, index) => (
    <a
      href={item.link}
      className="item"
      key={index}>

      <img
        src={item.img}
        className="item-img"
        alt={item.title} />

      <h2 className="item-title">
        {item.title.split("\n").map((line, index) => (
          <span key={index}>
            {line}<br />
          </span>
        ))}
      </h2>
    </a>
  ));
}

export default function Grid(images) {
  return (
    <div className="grid" style={style}>
      {Items(images)}
    </div>
  );
}