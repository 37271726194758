import React from "react";
import sketch from "./../bg-sketch";
import style from "../style/style.scss";

import Tiles from "./tiles";
import Content from "./content";
import Footer from "./footer";
import ParticleSketch from "./particle-sketch";

export default function Page() {
  return (
    <div style={style}>
      <div className="top">
        <Tiles />

        <div className="canvas-container">
          <ParticleSketch sketch={sketch} />
        </div>
      </div>

      <Content />
      <Footer />
    </div>
  );
}