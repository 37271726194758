import React from "react";

export default class CanvasComp extends React.Component {
  componentDidMount() {
    this.updateCanvas();
  }

  updateCanvas() {
    this.ctx = this.refs.canvas.getContext( "2d" );
    this.props.sketch( this.ctx, this.refs.canvas );
  }

  render() {
    return (
      <canvas
        ref="canvas"
        width={window.innerWidth}
        height={window.innerHeight}
      />
    );
  }
}