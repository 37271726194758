import React from "react";
import GithubLogo from "./../assets/github.svg";
import { history } from "./../index";
import { abortDrag, scrollTop, scrollContact, scrollProjects } from "./../util";
import style from "../style/topbar.scss";

function NavButton({ className, onClick, children }) {
  return (
    <p className={`tb-item ${ className || "" }`}
      onClick={() => {
        history.push("/");
        window.setTimeout(onClick, 100);
        onClick();
      }}>
      {children}
    </p>
  );
}

export default function Topbar() {
  return (
    <div id="topbar" style={style}>
      <NavButton onClick={scrollTop} className="tb-title">
        Jonas Karg
      </NavButton>

      <NavButton onClick={scrollProjects}>
        Projects
      </NavButton>

      <NavButton onClick={scrollContact}>
        Contact
      </NavButton>

      <a href="https://github.com/kargjonas">
        <img
          className="github-logo"
          src={GithubLogo}
          onDragStart={abortDrag}
          alt="github-logo" />
      </a>
    </div>
  );
}