import React from "react";
import style from "../style/notfound.scss";

export default function Notfound() {
  return (
    <div id="notfound" style={style}>
      <h1 className="title">404</h1>
      <h2 className="subtitle">Page not found.</h2>
    </div>
  );
}