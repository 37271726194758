import React from "react";

export default function Section({ title, marginTop = 150, children }) {
  return (
    <>
      <h2 className="ti-sub" style={{ marginTop }}>
        {title}
      </h2>

      <p className="text">
        {children}
      </p>
    </>
  );
}