import React from "react";
import style from "../style/title.scss";

export default function Title() {
  return (
    <div id="main-title" style={style}>
      <h2 className="first">
        Software
      </h2>

      <h1 className="second">
        is my business
      </h1>
    </div>
  );
}