import React from "react";
import { mapColor, scrollListen } from "../util";
import style from "../style/tiles.scss";

export default class Tiles extends React.Component {
  constructor() {
    super();

    this.amount = 20;
    this.colorSpread = 10;

    this.state = {
      scrollPosPx: 0,
      scrollPosPc: 0
    };

    this.tilesLeft = [];
    this.tilesRight = [];

    for (let i = 0; i < this.amount; i++) {
      const tile = this.Tile(i);

      if (i % 2 === 0) this.tilesLeft.push(tile);
      else this.tilesRight.push(tile);
    }
  }

  componentDidMount() {
    scrollListen((px, percent) => {
      this.setState({
        scrollPosPx: px,
        scrollPosPc: percent
      });
    });
  }

  Tile(key) {
    const color = mapColor(key / this.colorSpread, 200);
    const colorStyle = {
      borderColor: `rgb(${ color })`,
      backgroundColor: `rgba(${ color }, 0.2)`
    }

    const style = Object.assign({
      height: Math.random() * 150 + 150,
      transform: `scale(${ (this.amount - key / 2) / this.amount })`
    }, colorStyle);

    if (Math.random() * 4 <= 1) {
      return (
        <div className="tile" style={style} key={key}>
          <div className="secondary" style={colorStyle} />
        </div>
      );
    }

    return <div className="tile" style={style} key={key} />;
  }

  render() {
    const topOffsetStyle = {
      marginTop: -this.state.scrollPosPc
    };

    const offsetStyleLeft = {
      marginTop: `calc(100 + ${this.state.scrollPosPx}px / 8)`
    };

    const offsetStyleRight = {
      marginTop: `calc(${this.state.scrollPosPx}px / 16)`
    };

    return (
      <div style={style}>
        <div
        className="container"
        style={topOffsetStyle}>

        <div>
          <div style={offsetStyleLeft}>
            {this.tilesLeft}
          </div>

          <div style={offsetStyleRight}>
            {this.tilesRight}
          </div>
        </div>
      </div>
      </div>
    );
  }
}