import React from "react";
import style from "../style/footer.scss";

export default class Footer extends React.Component {
  constructor() {
    super();

    this.state = {
      contact: [],
      credit: []
    };

    fetch(`${ window.location.origin }/content/footer.json`)
      .then(response => response.json())
      .then(footerData => {
        this.setState((currentState) => {
          currentState = footerData;
          return currentState;
        });
      });
  }

  table(data) {
    const rows = data.map((row, index) => {
      const title = row[0];
      const link = row[1];

      return (
        <tr key={index}>
          <td className="name">{title}</td>

          <td>
            <a href={link}>
              {link.replace(/mailto:/, "")}
            </a>
          </td>
        </tr>
      );
    });

    return (
      <table>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div id="footer" style={style}>
        <div className="center-block">

          <h1 className="contact">Contact</h1>
          {this.table(this.state.contact)}

          <h1 className="credit">SVG credit</h1>
          {this.table(this.state.credit)}

          <hr />

          <p>{this.state.note}</p>
        </div>
      </div>
    );
  }
}