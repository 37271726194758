import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import { unregister } from "./serviceWorker";
import { Routes, Route } from "react-router";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";

import Page from "./components/page";
import Notfound from "./components/notfound";
import Topbar from "./components/topbar";
import "./style/fonts.scss";

const history = createBrowserHistory();

function App() {
  return (
    <div>
      <Topbar />
      <BrowserRouter history={history}>
        <Routes>
          <Route
              exact
              path="/"
              element={<Page />}
          />

          <Route
              element={<Notfound />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);

window.addEventListener("orientationchange", () => window.location.reload());
unregister();

export { history };